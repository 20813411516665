import React from 'react';

import ProductItem from '../../components/product-item/ProductItem';

import styles from './Admin.module.scss';

const Admin = () => {
  const isCanBuy = false;
  return (
    <div className="center">
      <div className={styles.adminPage}>
        <div className={styles.adminRow}>
          <div className={styles.adminHeader}>
            <div>LIST OF ORDERS</div>
          </div>
          <div className={styles.adminItem}>
            <div className={styles.adminItem__user}>
              <p className={styles.adminItem__title}>user ID </p>
              <p>1234-5678-9012</p>
            </div>
            <div className={styles.adminItem__order}>
              <ProductItem
                price="419.99"
                img="../images/Revolvers/Taurus_Revolver/Revolver_Taurus_38 Special_2” Barrel___TAURUS 856.webp"
                name="TAURUS 856"
                isCanBuy={isCanBuy}
              />
            </div>
          </div>
          <div className={styles.adminItem}>
            <div className={styles.adminItem__user}>
              <p className={styles.adminItem__title}>user ID </p>
              <p>4563-7898-0123</p>
            </div>
            <div className={styles.adminItem__order}>
              <ProductItem
                price="268.99"
                img="../images/Revolvers/Taurus_Revolver/Revolver_Taurus_.44 Magnum_4” Barrel___TRACKER 44.webp"
                name="TAURUS 44"
                isCanBuy={isCanBuy}
              />
              <ProductItem
                price="815.99"
                img="../images/Pistols/Pistol_Hammer Fired/Sig Saur_Pistol/Hammer Fired_Sig Saur_ 10mm_____P320-XTEN.jpg"
                name="P320-XTEN"
                isCanBuy={isCanBuy}
              />
              <ProductItem
                price="419.99"
                img="../images/Revolvers/Taurus_Revolver/Revolver_Taurus_38 Special_2” Barrel___TAURUS 856.webp"
                name="TAURUS 856"
                isCanBuy={isCanBuy}
              />
              <ProductItem
                price="289.99"
                img="../images/Pistols/Pistol_Hammer Fired/Browning_Pistol/Hammer Fired_Browning_.380 ACP___1911-380 Black Label Compact.webp"
                name="ACP Black Label Compact"
                isCanBuy={isCanBuy}
              />
            </div>
          </div>
          <div className={styles.adminItem}>
            <div className={styles.adminItem__user}>
              <p className={styles.adminItem__title}>user ID </p>
              <p>8910-7898-8425</p>
            </div>
            <div className={styles.adminItem__order}>
              <ProductItem
                price="419.99"
                img="../images/Revolvers/Taurus_Revolver/Revolver_Taurus_38 Special_2” Barrel___TAURUS 856.webp"
                name="TAURUS 856"
                isCanBuy={isCanBuy}
              />
              <ProductItem
                price="712.99"
                img="../images/Shot Guns/Browning_Shot guns/Shot Guns_Browning_10G_Semi Auto_____Gold Light 10 Gauge Field – Auric.webp"
                name="Gold Light 10 Gauge Field – Auric Gold Light 10 Gauge Field – Auric"
                isCanBuy={isCanBuy}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
