export const data = [
  {
    id: 1,
    name: 'TAURUS 856 ',
    price: '268.85',
    img: '../images/Revolvers/Taurus_Revolver/Revolver_Taurus_38 Special_2” Barrel___TAURUS 856.webp',
    isInWishlist: false,
  },
  {
    id: 2,
    name: 'P320-XTEN ',
    price: '268.85',
    img: '../images/Pistols/Pistol_Hammer Fired/Sig Saur_Pistol/Hammer Fired_Sig Saur_ 10mm_____P320-XTEN.jpg',
    isInWishlist: false,
  },
  {
    id: 3,
    name: 'TRACKER 44 ',
    price: '268.85',
    img: '../images/Revolvers/Taurus_Revolver/Revolver_Taurus_.44 Magnum_4” Barrel___TRACKER 44.webp',
    isInWishlist: false,
  },
  {
    id: 4,
    name: 'TRACKER 44 ',
    price: '268.85',
    img: '../images/Revolvers/Taurus_Revolver/Revolver_Taurus_.44 Magnum_4” Barrel___TRACKER 44.webp',
    isInWishlist: false,
  },
  {
    id: 5,
    name: 'P320-XTEN ',
    price: '268.85',
    img: '../images/Pistols/Pistol_Hammer Fired/Sig Saur_Pistol/Hammer Fired_Sig Saur_ 10mm_____P320-XTEN.jpg',
    isInWishlist: false,
  },
  {
    id: 6,
    name: '590 RETROGRADE ',
    price: '268.85',
    img: '../images/Shot Guns/Mossberg_Shot gun/Shot gun_Mossberg_12G_Pump-action____590 RETROGRADE.jpg',
    isInWishlist: false,
  },
  {
    id: 7,
    name: 'Auto_____Gold Light 10 Gauge Field – Auric ',
    price: '268.85',
    img: '../images/Shot Guns/Browning_Shot guns/Shot Guns_Browning_10G_Semi Auto_____Gold Light 10 Gauge Field – Auric.webp',
    isInWishlist: false,
  },
  {
    id: 8,
    name: '1911-380 Black Label Compact ',
    price: '268.85',
    img: '../images/Pistols/Pistol_Hammer Fired/Browning_Pistol/Hammer Fired_Browning_.380 ACP___1911-380 Black Label Compact.webp',
    isInWishlist: false,
  },
];
